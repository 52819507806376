.moreIcon {
    color: #d4d4d4;
}

.moreOption {
    :global(.MuiPopover-paper) {
        background-color: #171717;
        color: #d4d4d4;
        margin-top: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        border-radius: 16px;

        .optionLine {
            padding: 8px 12px;
            display: flex;
            align-items: center;
            gap: 8px;

            .text {
                min-width: 160px;
                line-height: 24px;
            }
        }
    }
}
